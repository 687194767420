

















































import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import useMarkers from "@/use/mapMarkers";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const { mapMarkers } = useMarkers();

    const model = reactive({
      locationId: "",
      marker: "default",
      tag: "",
      englishTag: "",
      additionalInfo: "",
      englishAdditionalInfo: "",
    });

    const state = reactive({
      empty: false,
      loading: false,
      loaded: false,
      success: false,
      error: false as boolean | number,
      locations: [],
      search: "",
      valid: false,
    });

    const fetchObject = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`conference-object/${root.$route.params.lid}`)
        .then(({ data }) => {
          model.locationId = data.location.id;
          model.marker = data.marker;
          model.tag = data.tag;
          model.englishTag = data.englishTag;
          model.additionalInfo = data.additionalInfo;
          model.englishAdditionalInfo = data.englishAdditionalInfo;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchObject);

    const fetchLocations = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("location", {
          params: {
            search: state.search || undefined,
          },
        })
        .then(({ data: { locations } }) => {
          state.empty = false;
          state.locations = locations.map((item: any) => ({
            id: item.id,
            name: item.name,
            city: item.city,
          }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.locations = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchLocations);

    watch(() => state.search, fetchLocations);

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return `${root.$tc("layout.errors.noPermission")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc(
            "panel.event.modules.locationModule.add.error409"
          )}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        locationId: model.locationId,
        marker: model.marker,
        tag: model.tag || null,
        englishTag: model.englishTag || null,
        additionalInfo: model.additionalInfo || null,
        englishAdditionalInfo: model.englishAdditionalInfo || null,
      };

      state.loading = true;

      axiosInstance
        .put(`conference-object/${root.$route.params.lid}`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc("layout.misc.saveSuccess"),
          });
          setTimeout(() => {
            root.$router.back();
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => (state.loading = false));
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    return { mapMarkers, model, state, onSubmit, getErrorMessage, rules };
  },
});
